//引入第一层axios
import http from '@/utils/http.js';
//普通暴露（data为调用函数时需要传入的参数对象）


//登录
export const userLogin = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/login',
        data,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}


// 发送验证码
export const userSmsSend = (data) => {
    return http({
        method: 'post',
        url: '/agent/sms/send',
        data,
    })
}

// 注册
export const userRegister = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/register',
        data,
    })
}

//忘记密码/修改密码
export const userForgetPwd = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/forgetPwd',
        data,
    })
}

export const serverCity = (data) => {
    return http({
        method: 'post',
        url: '/agent/open/manServer/serverCity',
        data  
    })
}

//实名认证-个人信息查询
export const identity = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/identity',
        data  
    })
}

//实名认证查询是否认证成功
export const authEnter = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/authEnter',
        data  
    })
}

//实名认证
export const auth = (data) => {
    return http({
        method: 'post',
        url: '/agent/order/auth',
        data  
    })
}


//公告详情
export const openNoticeInfo = (data) => {
    return http({
        method: 'post',
        url: '/agent/spread/openNoticeInfo',
        data  
    })
}

//软件下载
export const agentUrl = () => {
    return http({
        method: 'get',
        url: '/agent/spread/agentUrl',
    })
}


