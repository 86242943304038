<template>
        <div class="main">
                <div class="main-header">
                </div>

                <div class="main-content">
                        <div>©dietvi.net 2025</div>
                        <div style="margin-left: 20px;">
                                <!-- <router-link to="/privacyPolicy">Privacy Policy</router-link>
                                <router-link to="/aboutUs">About Us</router-link>
                                <router-link to="/contactUs">Contact Us</router-link> -->
                                <router-link :to="{ name: 'agreement', params: { name: item.title, id: item.id } }"
                                        v-for="(item, index) in dataList" :key="index">
                                        {{ item.title }}
                                </router-link>
                        </div>
                </div>

        </div>
</template>

<script>
// @ is an alias to /src
import { articleTypeList, suggestArticle, articleList, concernArticle, articleDetail, aboutWebsiteList, aboutWebsiteDetail } from '@/api/article.js'
import Login from '@/components/Login.vue'
export default {
        name: '',
        components: {
                Login
        },
        data() {
                return {
                        dataList: []
                }
        },
        async created() {
                let res = await aboutWebsiteList()
                //console.log(res.data.data.list);
                this.dataList = res.data.data.list
        },
        mounted() {

        },
        beforeDestroy() {

        },
        methods: {


        }
}
</script>



<style lang="less" scoped>
.main {
        width: 100%;
        position: relative;
}

.main-header {
        width: 100%;
        padding: 20px 0;
        // margin-top: 45px;
        background: #eeeeee;
}

.main-content {
        width: 100%;
        padding: 15px;
        text-align: center;
        color: #fff;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 100;

}

.main-content a {
        text-decoration: none;
        color: #fff;
        font-weight: bold;
        position: relative;
        margin: 10px;
        /* 给链接添加左侧外边距，使线条与文字有一定间隔 */
}

.main-content a::before {
        content: "";
        position: absolute;
        left: -10px;
        /* 调整伪元素的位置，使其位于链接文字左侧 */
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 1em;
        /* 高度与文字高度一致 */
        background-color: white;
}
</style>