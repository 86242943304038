<template>
    <div>

        <div class="header">
            <div style="margin-left: 200px;cursor: pointer;" @click="toHome()">
                <img src="../assets/image/logo99.jpg" alt="">
            </div>

            <div class="searchInput">
                <!-- 使用 v-model 进行双向数据绑定 -->
                <input type="text" placeholder="Search..." v-model="searchText1"
                    style="height:100%;width: 90%;background: #f1f1f1;" @keyup.enter="goSearch()">
                <img src="../assets/image/gb.png" alt=""
                    style="width: 12px;height: 12px;cursor: pointer;margin-left: 8px;" @click="clear()" v-show="searchText1">
                <img src="../assets/image/ss.png" alt=""
                    style="width: 20px;height: 20px;cursor: pointer;margin-left: 8px;" @click="goSearch()">
            </div>


        </div>

        <div class="main">
            <div class="main-l">
                <router-link to="/">HOME</router-link>
                <!-- <div @click="toHome()">HOME</div>
                <div v-for="(item, index) in typeList" :key="index" @click="goRouter(item)">{{ item.name }}</div> -->
                <router-link :to="{ name: 'category', params: { name: item.name, id: item.id } }"
                    v-for="(item, index) in typeList" :key="index">
                    {{ item.name }}
                </router-link>
                <!-- <router-link to="/health">HEALTH</router-link>
                <router-link to="/auto">AUTO</router-link>
                <router-link to="/finance">FINANCE</router-link>
                <router-link to="/lifestyle">LIFESTYLE</router-link>
                <router-link to="/travel">TRAVEL</router-link> -->
            </div>
            <!-- <div class="main-r">
                <img src="../assets/image/search.png" alt="" style="width: 20px;height: 20px;cursor: pointer;"
                    @click="openSearch()">
                <img src="../assets/image/console.png" alt=""
                    style="width: 20px;height: 20px;cursor: pointer;margin-left: 8px;" v-show="isShow"
                    @click="closeSearch()">

                <div class="main-r-search" style="width: 250px;" v-show="isShow">
                    <el-input clearable placeholder="搜索" v-model="searchText2">
                    </el-input>
                </div>
            </div> -->
        </div>


    </div>
</template>
<script>
import { workOrderStat } from '@/api/feedback.js'
// import { Message } from 'element-ui';
import { mapActions } from 'vuex';
//解构引入
// 引入封装的左右摆动动画组件
import ShakeInput from '@/components/ShakeInput.vue'

//引入登录注册等
import { userLogin, userSmsSend, userRegister, userForgetPwd } from '@/api/user.js'
import { articleTypeList, suggestArticle, articleList, concernArticle, articleDetail, articleTypeSearch, articleSearch } from '@/api/article.js'

import { userInfo } from '@/api/personal.js'
import { agentUrl } from '@/api/user.js'
//MD5加密
import CryptoJS from 'crypto-js';
import Login from '@/components/Login.vue'

import Captcha from '@/components/Captcha.vue'
import Vcode from "vue-puzzle-vcode";
import codeImg from "../assets/static/codeImg";

export default {
    name: 'Navbar',
    components: {


    },
    data() {
        return {
            searchText1: "",
            searchText2: "",
            isShow: false,
            typeList: [
                // { name: 'HEALTH' },
                // { name: 'AUTO' },
                // { name: 'FINANCE' },
                // { name: 'LIFESTYLE' },
                // { name: 'TRAVEL' }
            ],
        }
    },
    //监听
    watch: {

    },
    async created() {

        let res = await articleTypeList()
        //console.log(res.data.data, 333);
        this.typeList = res.data.data.list
    },
    async mounted() {

    },
    methods: {

        ...mapActions(['storeCategoryItem']),

        clear() {
            this.searchText1 = ""
            // 清空后立即发起空搜索
            // this.goSearch();
        },
        async goSearch() {
            // 这里可以编写搜索逻辑
            // await this.$nextTick();
            const searchKeyword = this.searchText1;
            // //console.log('执行搜索，搜索关键词为:', searchKeyword);
            // if (searchKeyword === "") {
            //     // 处理搜索关键词为空的情况，比如跳转到默认页面或给出提示
            //     //console.log('搜索关键词为空，不进行跳转');
            //     return;
            // }
            this.$router.push({
                name: 'search',
                params: {
                    info: searchKeyword
                }
            });
        },

        toHome() {
            this.$router.push('/home')
        },
        goCategory(item) {
            // 调用storeItem action存储item到Vuex
            this.$router.push({
                name: 'category',
                params: {
                    name: item.name,
                    id: item.id
                }
            });
        },


        toHome() {
            this.$router.push('/')
        },
        openSearch() {
            this.isShow = true
            //console.log(1111);
        },
        closeSearch() {
            //console.log(6666);
            this.isShow = false
        }







    }
}
</script>
<style lang="less" scoped>
.header {
    padding: 24px 0;
    text-align: center;
    background: var(--phnewscenter-header-background);
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.searchInput {
    width: 720px;
    // padding: 5px;
    height: 35px;
    background: #f1f1f1;
    // background: #48b5ec;
    margin-right: 150px;
    border-radius: 10px;
}

/deep/ .el-input__inner {
    -webkit-appearance: none;
    background-color: #f1f1f1 !important;
    background-image: none;
    border-radius: 8px;
    border: 1px solid #f1f1f1 !important;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
}

.main {
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.main-l {
    // display: flex;
}

.main-r {
    position: absolute;
    right: -400px;
    // background: salmon;
    height: 100%;
    position: relative;
}

.main-l a {
    text-align: center;
    height: 100%;
    background: rgb(0, 0, 0);
    padding: 14px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.main-l a:hover {
    background: #fff;
    color: #000;
}

.main-r-search {
    width: 250px;
    position: absolute;
    top: 34px;
    left: -200px;
}
</style>