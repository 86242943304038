<template>
    <div>
        <!-- 登录 -->
        <div style="width: 100%;height: 100%;background: rgba(0,0,0,.3);position: fixed;top: 0;left: 0; z-index: 1000;"
            v-if="loginBox1">
            <div
                style="position: fixed;top: 15%;left:37%;transform: translate(-50%,-50%);z-index: 998;transform-style: preserve-3d;perspective: 1000px;">
                <div class="login" :id="isRotate ? 'isRotate' : ''">
                    <!-- 取消按钮 -->
                    <img src="../assets/image/close-small.png" class="login-cancel" alt="" @click="close">
                    <!-- 账号密码登录就显示以下内容 -->
                    <div class="login-box" v-if="tab1Index == 1">
                        <!--logo -->
                        <div class="login-box-logo">
                            <img src="../assets/image/logo2.png" alt="">
                        </div>
                        <!-- tab切换登录方式 -->
                        <div class="login-box-tab">
                            <div class="box-tab-t" @click="goPwd()" :id="tab1Index == 1 ? 'activeTab' : ''">账密登录</div>
                            <p class="box-tab-l"></p>
                            <div class="box-tab-t" @click="goCode()" :id="tab1Index == 2 ? 'activeTab' : ''">验证码登录</div>
                        </div>
                        <!--tab切换的登录方式内容 -->
                        <div class="login-box-content">


                            <div :class="{ shake: errorField === 'phone' }">
                                <div class="box-content-inp">
                                    <!-- <div class="content-inp-a">+86</div> -->
                                    <div class="content-inp-item1-img1"></div>
                                    <input class="content-inp-phone" type="text" placeholder="手机号码" v-model="phone">
                                </div>
                            </div>





                            <div :class="{ shake: errorField === 'password' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img2"></div>
                                    <form id="myForm" @submit.prevent>
                                        <input class="content-inp-password" id="passwordInput1" type="password"
                                            placeholder="密码" v-model="password" autocomplete="off">
                                    </form>
                                    <img @click="changeEyes1()" v-show="eyeImage1 == false"
                                        src="../assets/image/eyes-c.png" class="content-inp-eyes" alt="">
                                    <img @click="changeEyes1()" v-show="eyeImage1" src="../assets/image/eyes-o.png"
                                        class="content-inp-eyes" alt="">
                                </div>
                            </div>


                            <transition name="fade2">
                                <!-- 错误弹出框 -->
                                <div class="error-alert" v-show="errorMessage">
                                    <img src="../assets/image/icon-cry.png" alt="">
                                    <span>{{ errorMessage }}</span>
                                </div>
                            </transition>

                        </div>

                        <!-- 自动登录选项 -->
                        <div class="box-content-select">
                            <div class="content-select-box">
                                <p>登录即代表同意
                                    <a href="/law.html" target="_blank">《法律声明》</a>
                                    <a href="/web.html" target="_blank">《亿品云网站服务条款》</a>
                                </p>
                                <!-- <el-checkbox v-model="checked" fill="#0E1130"></el-checkbox>
                                <p class="select-box-p">自动登录</p> -->
                            </div>
                            <div class="content-select-box">
                                <p class="select-box-p" @click="goForget">忘记密码</p>
                            </div>
                        </div>

                        <!-- 登录按钮 -->
                        <div class="box-content-btn" @click="validateForm">
                            登录
                        </div>

                        <!-- 跳转注册 -->
                        <div class="box-content-jump" style="margin-bottom: 10px;">
                            <p class="content-jump-p">还没有账号？</p>
                            <p class="content-jump-a" @click="goRegister" style="border-bottom: 1px solid  #4C6FF0;">
                                立即注册
                            </p>
                        </div>

                        <!-- 用户协议 -->
                        <!-- <div class="box-content-agree">
                            <el-checkbox v-model="checkedAgree" fill="#0E1130"></el-checkbox>
                            <p class="content-agree-p">我已阅读并同意 <a class="content-agree-a" href="">《用户协议》</a> 和 <a
                                    class="content-agree-a" href="">《用户承诺书》</a> </p>

                        </div> -->
                    </div>


                    <!-- 验证码登录就显示以下内容 -->
                    <div class="login-box" v-if="tab1Index == 2">
                        <!--logo -->
                        <div class="login-box-logo">
                            <img src="../assets/image/logo2.png" alt="">
                        </div>
                        <!-- tab切换登录方式 -->
                        <div class="login-box-tab">
                            <div class="box-tab-t" @click="goPwd()" :id="tab1Index == 1 ? 'activeTab' : ''">账密登录</div>
                            <p class="box-tab-l"></p>
                            <div class="box-tab-t" @click="goCode()" :id="tab1Index == 2 ? 'activeTab' : ''">验证码登录</div>
                        </div>
                        <!--tab切换的登录方式内容 -->
                        <div class="login-box-content">

                            <div :class="{ shake: errorField === 'phone' }">
                                <div class="box-content-inp">
                                    <!-- <div class="content-inp-a">+86</div> -->
                                    <div class="content-inp-item1-img1"></div>
                                    <input class="content-inp-phone" type="text" placeholder="手机号码" v-model="phone">
                                </div>
                            </div>

                            <div :class="{ shake: errorField === 'puzzle' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img2"></div>
                                    <div v-if="isAllow == true" style="display: flex;align-items: center;">
                                        <div class="content-inp-item2">人机验证已完成~ </div>
                                        <img src="../assets/image/icon-correct.png" alt=""
                                            style="width: 16px;height: 16px;margin-left: 8px;vertical-align: middle;">
                                    </div>

                                    <div class="content-inp-item1" v-else @click="showPuzzleCode">点击进行人机验证™ </div>
                                </div>
                            </div>



                            <div :class="{ shake: errorField === 'sendCode' }">
                                <div class="box-content-inp" style="justify-content: space-between;">
                                    <div class="content-inp-item1-img2"></div>
                                    <input class="content-inp-phone" style="width: 260px;" type="text"
                                        placeholder="请输入短信验证码" v-model="smsCode">
                                    <!-- <input class="content-inp-dxCode" type="text" placeholder="短信验证码"> -->

                                    <div class="content-inp2-btn" @click="sendCode(2)" :disabled="counter > 0">
                                        <p :id="counter > 0 ? 'unclickable' : ''" class="inp2-btn-p">{{ counter > 0 ?
                                            `重新获取(${counter}s)` : '发送验证码' }}</p>
                                    </div>
                                </div>
                            </div>
                            <transition name="fade2">
                                <!-- 错误弹出框 -->
                                <div class="error-alert" v-show="errorMessage">
                                    <img src="../assets/image/icon-cry.png" alt="">
                                    <span>{{ errorMessage }}</span>
                                </div>
                            </transition>



                        </div>

                        <!-- 自动登录选项 -->
                        <div class="box-content-select">
                            <div class="content-select-box">
                                <p>登录即代表同意
                                    <a href="/law.html" target="_blank">《法律声明》</a>
                                    <a href="/web.html" target="_blank">《亿品云网站服务条款》</a>
                                </p>
                                <!-- <el-checkbox v-model="checked" fill="#0E1130"></el-checkbox>
                                <p class="select-box-p">自动登录</p> -->
                            </div>
                            <div class="content-select-box">
                                <p class="select-box-p" @click="goForget">忘记密码</p>
                            </div>
                        </div>

                        <!-- 登录按钮 -->
                        <div class="box-content-btn" @click="codeLogin">
                            登录
                        </div>

                        <!-- 跳转注册 -->
                        <div class="box-content-jump" style="margin-bottom: 10px;">
                            <p class="content-jump-p">还没有账号？</p>
                            <p class="content-jump-a" @click="goRegister" style="border-bottom: 1px solid  #4C6FF0;">
                                立即注册
                            </p>
                        </div>

                        <!-- 用户协议 -->
                        <!-- <div class="box-content-agree">
                            <el-checkbox v-model="checkedAgree" fill="#0E1130"></el-checkbox>
                            <p class="content-agree-p">我已阅读并同意 <a class="content-agree-a" href="">《用户协议》</a> 和 <a
                                    class="content-agree-a" href="">《用户承诺书》</a> </p>

                        </div> -->
                    </div>

                </div>
            </div>
        </div>






        <!-- 注册 -->
        <div style="width: 100%;height: 100%;background: rgba(0,0,0,.3);position: fixed;top: 0;left: 0;z-index: 1000;"
            v-if="loginBox2">
            <div
                style="position: fixed;top: 15%;left:37%;transform: translate(-50%,-50%);z-index: 998;transform-style: preserve-3d;perspective: 1000px;">
                <div class="login" :id="isRotate ? 'isRotate' : ''">
                    <!-- 取消按钮 -->
                    <img src="../assets/image/close-small.png" class="login-cancel" alt="" @click="close">
                    <!-- 注册就显示以下内容 -->
                    <div class="login-box" v-if="tab2Index == 1">
                        <!--logo -->
                        <div class="login-box-logo">
                            <img src="../assets/image/logo2.png" alt="">
                            <h1 class="box-logo-h1">注册账号</h1>
                            <!-- <p class="box-logo-p">成功注册即送1000</p> -->
                        </div>
                        <!-- tab切换登录方式 -->
                        <!-- <div class="login-box-tab">
                            <div class="box-tab-t" @click="goPwd()" :id="tabIndex == 1 ? 'activeTab' : ''">账密登录</div>
                    <p class="box-tab-l"></p>
                    <div class="box-tab-t" @click="goCode()" :id="tabIndex == 2 ? 'activeTab' : ''">验证码登录</div>
                </div> -->
                        <!--tab切换的登录方式内容 -->
                        <div class="login-box-content">

                            <div :class="{ shake: errorField === 'phone' }">
                                <div class="box-content-inp">
                                    <!-- <div class="content-inp-a">+86</div> -->
                                    <div class="content-inp-item1-img1"></div>
                                    <input class="content-inp-phone" type="text" placeholder="手机号码" v-model="phone">
                                </div>
                            </div>

                            <div :class="{ shake: errorField === 'puzzle' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img2"></div>
                                    <div v-if="isAllow == true" style="display: flex;align-items: center;">
                                        <div class="content-inp-item2">人机验证已完成~ </div>
                                        <img src="../assets/image/icon-correct.png" alt=""
                                            style="width: 16px;height: 16px;margin-left: 8px;vertical-align: middle;">
                                    </div>

                                    <div class="content-inp-item1" v-else @click="showPuzzleCode">点击进行人机验证™ </div>
                                </div>
                            </div>

                            <div :class="{ shake: errorField === 'sendCode' }">
                                <div class="box-content-inp" style="justify-content: space-between;">
                                    <div class="content-inp-item1-img2"></div>
                                    <input class="content-inp-phone" style="width: 260px;" type="text"
                                        placeholder="请输入短信验证码" v-model="smsCode">
                                    <!-- <input class="content-inp-dxCode" type="text" placeholder="短信验证码"> -->
                                    <div class="content-inp2-btn" @click="sendCode(1)" :disabled="counter > 0">
                                        <p :id="counter > 0 ? 'unclickable' : ''" class="inp2-btn-p">{{ counter > 0 ?
                                            `重新获取(${counter}s)` : '发送验证码' }}</p>
                                    </div>
                                </div>
                            </div>

                            <div :class="{ shake: errorField === 'password' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img3"></div>
                                    <form id="myForm" @submit.prevent>
                                        <input class="content-inp-password" id="passwordInput1" type="password"
                                            placeholder="密码" v-model="password" autocomplete="off">
                                    </form>
                                    <img @click="changeEyes1()" v-show="eyeImage1 == false"
                                        src="../assets/image/eyes-c.png" class="content-inp-eyes" alt="">
                                    <img @click="changeEyes1()" v-show="eyeImage1" src="../assets/image/eyes-o.png"
                                        class="content-inp-eyes" alt="">
                                </div>
                            </div>


                            <transition name="fade2">
                                <!-- 错误弹出框 -->
                                <div class="error-alert" v-show="errorMessage">
                                    <img src="../assets/image/icon-cry.png" alt="">
                                    <span>{{ errorMessage }}</span>
                                </div>
                            </transition>


                        </div>

                        <!-- 自动登录选项 -->
                        <!-- <div class="box-content-select">
                            <div class="content-select-box">
                                <el-checkbox v-model="checked" fill="#0E1130"></el-checkbox>
                                <p class="select-box-p">自动登录</p>
                            </div>
                            <div class="content-select-box">
                                <p class="select-box-p" @click="goForget">忘记密码</p>
                            </div>
                        </div> -->

                        <!-- 注册 -->
                        <div class="box-content-btn" @click="registerBtn">
                            注册
                        </div>

                        <!-- 跳转登录 -->
                        <div class="box-content-jump" style="margin-bottom: 10px;">
                            <p class="content-jump-p">已有账号？</p>
                            <p class="content-jump-a" style="border-bottom: 1px solid  #4C6FF0;" @click="goLogin">立即登录
                            </p>
                        </div>

                        <!-- 用户协议 -->
                        <div class="box-content-agree">
                            <el-checkbox v-model="checkedAgree" fill="#0E1130"></el-checkbox>
                            <p class="content-agree-p">我已阅读并同意
                                <a class="content-agree-a" href="/reg.html" target="_blank">《亿品云用户注册协议》</a>
                            </p>

                        </div>
                    </div>



                    <!-- 忘记密码 -->
                    <div class="login-box" v-if="tab2Index == 2">
                        <!--logo -->
                        <div class="login-box-logo">
                            <img src="../assets/image/logo2.png" alt="">
                            <h1 class="box-logo-h1">找回密码</h1>
                            <!-- <p class="box-logo-p" style="color: #9E99AE;">成功验证手机号即可找回密码</p> -->
                        </div>
                        <!--tab切换的登录方式内容 -->
                        <div class="login-box-content">


                            <div :class="{ shake: errorField === 'phone' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img1"></div>
                                    <input class="content-inp-phone" type="text" placeholder="手机号码" v-model="phone">
                                </div>
                            </div>

                            <div :class="{ shake: errorField === 'puzzle' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img2"></div>
                                    <div v-if="isAllow == true" style="display: flex;align-items: center;">
                                        <div class="content-inp-item2">人机验证已完成~ </div>
                                        <img src="../assets/image/icon-correct.png" alt=""
                                            style="width: 16px;height: 16px;margin-left: 8px;vertical-align: middle;">
                                    </div>
                                    <div class="content-inp-item1" v-else @click="showPuzzleCode">点击进行人机验证™ </div>
                                </div>
                            </div>

                            <div :class="{ shake: errorField === 'sendCode' }">
                                <div class="box-content-inp" style="justify-content: space-between;">
                                    <div class="content-inp-item1-img2"></div>
                                    <input class="content-inp-phone" style="width: 260px;" type="text"
                                        placeholder="请输入短信验证码" v-model="smsCode">
                                    <!-- <input class="content-inp-dxCode" type="text" placeholder="短信验证码"> -->
                                    <div class="content-inp2-btn" @click="sendCode(3)" :disabled="counter > 0">
                                        <p :id="counter > 0 ? 'unclickable' : ''" class="inp2-btn-p">{{ counter > 0 ?
                                            `重新获取(${counter}s)` : '发送验证码' }}</p>
                                    </div>
                                </div>
                            </div>

                            <div :class="{ shake: errorField === 'password' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img3"></div>
                                    <form id="myForm" @submit.prevent>
                                        <input class="content-inp-password" id="passwordInput1" type="password"
                                            placeholder="密码" v-model="password" autocomplete="off">
                                    </form>
                                    <img @click="changeEyes1()" v-show="eyeImage1 == false"
                                        src="../assets/image/eyes-c.png" class="content-inp-eyes" alt="">
                                    <img @click="changeEyes1()" v-show="eyeImage1" src="../assets/image/eyes-o.png"
                                        class="content-inp-eyes" alt="">
                                </div>
                            </div>


                            <div :class="{ shake: errorField === 'newPassword' }">
                                <div class="box-content-inp">
                                    <div class="content-inp-item1-img3"></div>
                                    <form id="myForm" @submit.prevent>
                                        <input class="content-inp-password" id="passwordInput2" type="password"
                                            placeholder="确认新密码" v-model="newPassword">
                                    </form>

                                    <img @click="changeEyes2()" v-show="eyeImage2 == false"
                                        src="../assets/image/eyes-c.png" class="content-inp-eyes" alt="">
                                    <img @click="changeEyes2()" v-show="eyeImage2" src="../assets/image/eyes-o.png"
                                        class="content-inp-eyes" alt="">
                                </div>
                            </div>
                            <transition name="fade2">
                                <!-- 错误弹出框 -->
                                <div class="error-alert" v-show="errorMessage">
                                    <img src="../assets/image/icon-cry.png" alt="">
                                    <span>{{ errorMessage }}</span>
                                </div>
                            </transition>
                        </div>

                        <!-- 自动登录选项 -->
                        <!-- <div class="box-content-select">
                    <div class="content-select-box">
                        <el-checkbox v-model="checked" fill="#0E1130"></el-checkbox>
                        <p class="select-box-p">自动登录</p>
                    </div>
                    <div class="content-select-box">
                        <p class="select-box-p" @click="forget">忘记密码</p>
                    </div>
                </div> -->

                        <!-- 登录按钮 -->
                        <div class="box-content-btn" @click="ChangePassword">
                            修改密码
                        </div>

                        <!-- 跳转注册 -->
                        <div class="box-content-jump">
                            <p class="content-jump-p">已有账号？</p>
                            <p class="content-jump-a" style="border-bottom: 1px solid  #4C6FF0;" @click="goLogin">立即登录
                            </p>
                        </div>

                        <!-- 用户协议 -->
                        <!-- <div class="box-content-agree">
                    <el-checkbox v-model="checkedAgree" fill="#0E1130"></el-checkbox>
                    <p class="content-agree-p">我已阅读并同意 <a class="content-agree-a" href="">《用户协议》</a> 和 <a
                            class="content-agree-a" href="">《用户承诺书》</a> </p>
                </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- 拼图验证组件 -->
        <Vcode :show="isShow" :imgs="imgsrc" @success="onSuccess" @close="onClose" @fail="onFail"
            :style="{ zIndex: 1111 }" />
    </div>
</template>
<script>

    // import { Message } from 'element-ui';

    //解构引入
    // 引入封装的左右摆动动画组件
    import ShakeInput from '@/components/ShakeInput.vue'

    //引入登录注册等
    import { userLogin, userSmsSend, userRegister, userForgetPwd } from '@/api/user.js'


    //MD5加密
    import CryptoJS from 'crypto-js';


    import Captcha from '@/components/Captcha.vue'
    import Vcode from "vue-puzzle-vcode";
    import codeImg from "../assets/static/codeImg";
    export default {
        name: 'Navbar',
        components: {
            Captcha,
            Vcode,
            ShakeInput
        },
        data() {
            return {
                userPhone: '',
                token: '',
                // 定义一个验证码的值用于判断是否为空为空就触发动画
                smsCode: '',
                // 拼图验证是否通过
                isAllow: false,
                isShow: false,
                imgsrc: codeImg,
                // 登录
                loginBox1: false,
                // 注册
                loginBox2: false,
                passwordShow1: null,
                eyeImage1: false,
                passwordShow2: null,
                eyeImage2: false,
                // 自动登录选项
                checked: true,
                // 是否同意协议
                checkedAgree: true,
                // 切换账号登录和密码登录的index
                tab1Index: 1,
                // 切换注册和忘记密码的index
                tab2Index: 1,
                // 验证码倒计时
                counter: 0,
                //是否旋转
                isRotate: false,
                // 滑块组件
                isPassing: false,
                phone: '',
                password: '',
                newPassword: '',
                shakePhone: false,
                shakePassword: false,
                shakeNewPassword: false,
                errorMessage: '',
                errorField: null, // 新增一个属性用于标记出错的字段,
                token: '',

            }
        },
        //监听
        watch: {
            // phone(newV,oldV){
            //    // ////////////console.lognewV,oldV,'有吗');
            // }
            // token(nweValue,oldValue){
            //    // ////////////console.lognweValue,oldValue,'token更新了');
            //     if(nweValue){
            //        // ////////////console.log'不等于空的');
            //     }
            // }
        },
        created() {

            //获取token
            this.token = localStorage.getItem('token')

            //页面加载先看一下有没有token有token就不需要登录 
            if (localStorage.getItem('token')) {
               // ////////////console.log'有token');
                this.token = localStorage.getItem('token')
            }

            if (localStorage.getItem('userPhone')) {
                this.userPhone = localStorage.getItem('userPhone').replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
            }

        },
        mounted() {

        },
        methods: {


            login() {
                if (this.loginBox1 == false) {
                    this.loginBox1 = true
                }
            },
            register() {
                if (this.loginBox2 == false) {
                    this.loginBox2 = true
                }
            },
            changeEyes1() {
                this.eyeImage1 = !this.eyeImage1
                // this.passwordShow1 == 'password' ? this.passwordShow1 = 'text' : this.passwordShow1 = 'password';
                let passwordInput = document.getElementById('passwordInput1')
                //// ////////////console.logpasswordInput,'是什么玩意');
                if (passwordInput.type === 'password') {
                    passwordInput.type = 'text'
                } else {
                    passwordInput.type = 'password'
                }
            },

            changeEyes2() {
                this.eyeImage2 = !this.eyeImage2
                // this.passwordShow2 == 'password' ? this.passwordShow2 = 'text' : this.passwordShow2 = 'password';
                let passwordInput = document.getElementById('passwordInput2')
                //// ////////////console.logpasswordInput,'是什么玩意');
                if (passwordInput.type === 'password') {
                    passwordInput.type = 'text'
                } else {
                    passwordInput.type = 'password'
                }

            },
            goPwd() {
                // 定义一个验证码的值用于判断是否为空为空就触发动画
                this.smsCode = '',
                    // 验证码倒计时
                    this.counter = 0,
                    this.phone = '',
                    this.password = '',
                    this.newPassword = '',
                    this.shakePhone = false,
                    this.shakePassword = false,
                    this.shakeNewPassword = false,
                    this.errorMessage = '',
                    this.tab1Index = 1
            },
            goCode() {
                // 定义一个验证码的值用于判断是否为空为空就触发动画
                this.smsCode = '',
                    // 验证码倒计时
                    this.counter = 0,
                    this.phone = '',
                    this.password = '',
                    this.newPassword = '',
                    this.shakePhone = false,
                    this.shakePassword = false,
                    this.shakeNewPassword = false,
                    this.errorMessage = '',
                    this.tab1Index = 2
            },

            //单独属于注册的发送验证码
            async sendCode(type) {
                if (!this.validatePhone()) {
                    this.errorField = 'phone';
                    this.errorMessage = '手机号格式不正确';
                    // 如果手机号不通过验证，不需要进行密码验证,并弹出错误信息
                    this.timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(this.timer)
                    }, 2000)
                    return;
                }

                // 如果计时器大于0就代表已经执行了 就不需要做任何操作
                if (this.counter > 0) {
                    return
                }
                // 没有大于0 那就是重新点的意思 那就重新定义一个倒计时 没过一秒-1 直到等于0的时候清楚这个定时器
                this.counter = 60

                //点击发送验证码获取发送验证码请求数据
                let res = await userSmsSend({
                    mobile: this.phone,
                    type: type
                })

                if (res.data.code === 200) {
                   // ////////////console.log'发送成功');

                    const intervalId = setInterval(() => {
                        this.counter--
                        if (this.counter === 0) {
                            clearInterval(intervalId)
                        }
                    }, 1000)
                } else {
                    this.counter = 0
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            },
            close() {


                if (this.loginBox1) {
                    // 定义一个验证码的值用于判断是否为空为空就触发动画
                    this.smsCode = '',
                        // 验证码倒计时
                        this.counter = 0,
                        this.phone = '',
                        this.password = '',
                        this.newPassword = '',
                        this.shakePhone = false,
                        this.shakePassword = false,
                        this.shakeNewPassword = false,
                        this.errorMessage = '',
                        // 拼图验证是否通过
                        this.isAllow = false,
                        this.isShow = false,

                        this.loginBox1 = false
                    this.isRotate = false
                }

                if (this.loginBox2) {
                    // 定义一个验证码的值用于判断是否为空为空就触发动画
                    this.smsCode = '',
                        // 验证码倒计时
                        this.counter = 0,
                        this.phone = '',
                        this.password = '',
                        this.newPassword = '',
                        this.shakePhone = false,
                        this.shakePassword = false,
                        this.shakeNewPassword = false,
                        this.errorMessage = '',
                        this.isAllow = false,
                        this.isShow = false,



                        this.loginBox2 = false
                    this.isRotate = false

                }
                localStorage.removeItem('errMsg')

            },
            //点击忘记密码
            goForget() {
                // 定义一个验证码的值用于判断是否为空为空就触发动画
                this.smsCode = '',
                    // 验证码倒计时
                    this.counter = 0,
                    this.phone = '',
                    this.password = '',
                    this.newPassword = '',
                    this.shakePhone = false,
                    this.shakePassword = false,
                    this.shakeNewPassword = false,
                    this.errorMessage = '',
                    this.isAllow = false,
                    this.isShow = false,


                    this.loginBox1 = false
                this.loginBox2 = true
                this.tab2Index = 2
                this.isAllow = false
            },

            // 点击去注册
            goRegister() {
                // 定义一个验证码的值用于判断是否为空为空就触发动画
                this.smsCode = '',
                    // 验证码倒计时
                    this.counter = 0,
                    this.phone = '',
                    this.password = '',
                    this.newPassword = '',
                    this.shakePhone = false,
                    this.shakePassword = false,
                    this.shakeNewPassword = false,
                    this.errorMessage = '',
                    this.isAllow = false,
                    this.isShow = false,



                    this.loginBox1 = false
                this.loginBox2 = true
                this.isRotate = true
                this.tab2Index = 1
                this.isAllow = false
            },
            // 点击去登录
            goLogin() {
                // 定义一个验证码的值用于判断是否为空为空就触发动画
                this.smsCode = '',
                    // 验证码倒计时
                    this.counter = 0,
                    this.phone = '',
                    this.password = '',
                    this.newPassword = '',
                    this.shakePhone = false,
                    this.shakePassword = false,
                    this.shakeNewPassword = false,
                    this.errorMessage = '',
                    this.isAllow = false,
                    this.isShow = false,

                    this.loginBox2 = false
                this.loginBox1 = true
                this.isRotate = true
                this.tab1Index = 1
                this.isAllow = false

            },



            // 父组件通过自定义事件获取到子组件的验证码
            handleVertifyCode(code) {
               // ////////////console.logcode, '子组件的验证码');
            },


            showPuzzleCode() {
                this.isShow = true
            },
            // 拼图成功回调
            onSuccess() {
               // ////////////console.log'成功回调');
                this.isShow = false
                this.isAllow = true
            },
            //拼图失败回调
            onFail() {
               // ////////////console.log'失败回调', this.isAllow);

            },
            // 点击拼图遮罩层
            onClose() {
               // ////////////console.log'点击遮罩层回调');
                this.isShow = false
            },

            //将手机号变成****
            // maskPhoneNumber (phoneNumber) {
            //     return phoneNumber.;
            // },

            //点击退出登录
            exitLogin() {
                // 退出登录清空token
               // ////////////console.log'点击退出登录');

                this.$confirm('确定退出登录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    localStorage.clear()
                    this.$router.replace({ path: '/' })
                    localStorage.removeItem('errMsg')
                    window.location.reload()
                    // this.$message({
                    //     type: 'success',
                    //     message: '成功退出!'
                    // });

                    // window.location.reload()
                    // this.$router.push('/purchase')
                }).catch(() => {
                    // this.$message({
                    //     type: 'warning',
                    //     message: '已取消退出!'
                    // });
                });
            },



            //忘记密码
            async ChangePassword() {
                // 先重置错误信息
                this.errorMessage = '';
                this.errorField = null;
                let timer = null

                if (!this.validatePhone()) {
                    this.errorField = 'phone';
                    this.errorMessage = '手机号格式不正确';
                    // 如果手机号不通过验证，不需要进行密码验证,并弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                //拼图验证
                if (!this.isAllow) {
                    this.errorField = 'puzzle';
                    this.errorMessage = '请完成人机验证';
                    // 如果拼图不通过验证，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }


                // 短信验证码
                if (!this.smsCode) {
                   // ////////////console.log'空的');
                    this.errorField = 'sendCode';
                    this.errorMessage = '请输入短信验证码';
                    // 如果短信为空，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                if (!this.validatePassword()) {
                    this.errorField = 'password';
                    this.errorMessage = '密码必须包含数字和字母，并且长度至少6位';
                    // 如果密码不通过验证，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                if (this.newPassword != this.password) {
                    this.errorField = 'newPassword';
                    this.errorMessage = '密码不一致';
                    // 如果密码不通过验证，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                const MD5Password = CryptoJS.MD5(this.password).toString();
                // 当所有条件都满足就可以修改密码了
                let res = await userForgetPwd({
                    mobile: this.phone,
                    sms_code: this.smsCode,
                    password: MD5Password,
                    password2: MD5Password
                })
               // ////////////console.logres, '修改密码结果');
                if (res.data.code === 200) {
                    //前往登录
                    this.loginBox2 = false
                    this.loginBox1 = true
                    localStorage.removeItem('errMsg')
                    //登陆成功弹出绿色提示框（可选）
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }


            },
            //注册
            async registerBtn() {
                //  let   promotion_code = localStorage.getItem('promotionCode')
                //    // ////////////console.log'点击注册',promotion_code);
                // 先重置错误信息
                this.errorMessage = '';
                this.errorField = null;
                let timer = null

                if (!this.validatePhone()) {
                    this.errorField = 'phone';
                    this.errorMessage = '手机号格式不正确';
                    // 如果手机号不通过验证，不需要进行密码验证,并弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                //拼图验证
                if (!this.isAllow) {
                    this.errorField = 'puzzle';
                    this.errorMessage = '请完成人机验证';
                    // 如果拼图不通过验证，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }


                // 短信验证码
                if (!this.smsCode) {
                   // ////////////console.log'空的');
                    this.errorField = 'sendCode';
                    this.errorMessage = '请输入短信验证码';
                    // 如果短信为空，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                if (!this.validatePassword()) {
                    this.errorField = 'password';
                    this.errorMessage = '密码必须包含数字和字母，并且长度至少6位';
                    // 如果密码不通过验证，弹出错误信息

                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }


                // 是否同意协议
                if (!this.checkedAgree) {
                    this.errorMessage = '请先阅读并同意协议！';
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                //因为上面的条件都要return 当条件不满足就会跳出去 就不会执行下面的代码当所有条件都满足就可以调取注册接口了 

               // ////////////console.log'可以注册了', this.loginBox1, this.loginBox2);


                const MD5Password = CryptoJS.MD5(this.password).toString();
                // 推广码
                let promotion_code = sessionStorage.getItem('promotionCode')
                let res = await userRegister({
                    mobile: this.phone,
                    sms_code: this.smsCode,
                    password: MD5Password,
                    promotion_code: promotion_code
                })
               // ////////////console.logres, '注册结果');
                if (res.data.code === 200) {
                    //前往登录
                    this.loginBox2 = false
                    this.loginBox1 = true
                    localStorage.removeItem('errMsg')
                    //登陆成功弹出绿色提示框（可选）
                    this.$message({
                        message: '注册成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }




            },
            // 验证码登录
            async codeLogin() {
               // ////////////console.log'点击验证码登录');
                // 先重置错误信息
                this.errorMessage = '';
                this.errorField = null;
                let timer = null

                if (!this.validatePhone()) {
                    this.errorField = 'phone';
                    this.errorMessage = '手机号格式不正确';
                    // 如果手机号不通过验证，不需要进行密码验证,并弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                //拼图验证
                if (!this.isAllow) {
                    this.errorField = 'puzzle';
                    this.errorMessage = '请完成人机验证';
                    // 如果拼图不通过验证，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }


                // 短信验证码
                if (!this.smsCode) {
                   // ////////////console.log'空的');
                    this.errorField = 'sendCode';
                    this.errorMessage = '请输入短信验证码';
                    // 如果短信为空，弹出错误信息
                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                let res = await userLogin({
                    mobile: this.phone,
                    sms_code: this.smsCode,
                    // type2密码登录 type1验证码登录
                    type: 1
                })
               // ////////////console.logres, '验证码登录');
                // 登录成功保存token
                if (res.data.code === 200) {
                    this.loginBox1 = false
                    localStorage.setItem('token', res.data.data.token)
                    localStorage.setItem('userId', res.data.data.u)
                    localStorage.setItem('userPhone', this.phone)
                   
                    localStorage.removeItem('errMsg')
                    this.$router.replace({ path: '/' })
                    
                    window.location.reload()
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                } else {

                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }

            },


            // 点击登录获取token
            async validateForm() {
                // 先重置错误信息
                this.errorMessage = '';
                this.errorField = null;
                let timer = null

                if (!this.validatePhone()) {
                    this.errorField = 'phone';
                    this.errorMessage = '手机号格式不正确';
                    // 如果手机号不通过验证，不需要进行密码验证,并弹出错误信息


                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                if (!this.validatePassword()) {
                    this.errorField = 'password';
                    this.errorMessage = '密码必须包含数字和字母，并且长度至少6位';
                    // 如果密码不通过验证，弹出错误信息

                    timer = setTimeout(() => {
                        this.errorMessage = null;
                        clearTimeout(timer)
                    }, 2000)
                    return;
                }

                // let data = {
                //     mobile: '11111111111',
                //     password: '123456',
                //     // type:1短信验证码 2 账号密码
                //     type: 2
                // }

                const MD5Password = CryptoJS.MD5(this.password).toString();
               ////////////console.logMD5Password, '加密后的密码');

                let res = await userLogin({
                    mobile: this.phone,
                    password: MD5Password,
                    // type2密码登录 type1验证码登录
                    type: 2
                })
               // ////////////console.logres, '登陆了！！！！');

                // 登录成功保存token
                if (res.data.code === 200) {
                    this.loginBox1 = false
                    localStorage.setItem('token', res.data.data.token)
                    localStorage.setItem('userId', res.data.data.u)
                    localStorage.setItem('userPhone', this.phone)
                    // this.$router.replace({ path: '/' })
                    // 假设你正要导航到根路径 '/'
                    if (this.$router.currentRoute.path !== '/') {
                        this.$router.push('/').catch(err => {
                            // 导航守卫里可能会发生未捕获的错误
                            // 但是并不总是真正的问题，可以忽略 NavigationDuplicated 错误
                            if (err.name !== 'NavigationDuplicated') {
                                throw err;
                            }
                        });
                    }
                    localStorage.removeItem('errMsg')
                    window.location.reload()

                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });



                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }


            },


            validatePhone() {
                if (this.phone.trim() === '' || !this.isValidPhoneNumber(this.phone)) {
                    this.triggerShakeAnimation('shakePhone');
                    return false;
                } else {
                    this.resetShakeAnimation('shakePhone');
                    return true;
                }
            },

            validatePassword() {
                if (this.password.trim() === '' || this.password.length < 6 || !this.hasNumbersAndLetters(this.password)) {
                    this.triggerShakeAnimation('shakePassword');
                    return false;
                } else {
                    this.resetShakeAnimation('shakePassword');
                    return true;
                }
            },

            validateNewPassword() {
                if (this.newPassword != this.password) {
                    this.triggerShakeAnimation('newPassword');
                    return false;
                } else {
                    this.resetShakeAnimation('newPassword');
                    return true;
                }
            },

            // 手机号正则判断
            isValidPhoneNumber(phone) {
                const phoneRegex = /^1[3-9]\d{9}$/; // Regex pattern for valid CN phone number
                return phoneRegex.test(phone);
            },

            // 密码正则判断
            hasNumbersAndLetters(password) {
                const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
                return passwordRegex.test(password);
            },
            triggerShakeAnimation(stateProp) {
                this[stateProp] = false;
                this.$nextTick(() => {
                    // 加入 setTimeout 是为了确保能够重新触发 CSS 动画
                    setTimeout(() => { this[stateProp] = true; }, 10);
                });
            },
            resetShakeAnimation(stateProp) {
                this[stateProp] = false;
            },



            //阻止from表单默认提交行为
            func(event) {
                event.preventDefault()
            }
        }
    }
</script>
<style lang="less" scoped>
    // 左右摆动动画
    @keyframes shake {

        0%,
        100% {
            transform: translateX(0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            transform: translateX(-10px);
        }

        20%,
        40%,
        60%,
        80% {
            transform: translateX(10px);
        }
    }

    .shake {
        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    }







    //旋转动作
    #isRotate {
        animation: rotateY 1s forwards;
    }

    //可不可以点击
    #isClick {
        pointer-events: none;
    }

    #unclickable {
        opacity: 0.5;
    }


    //单选框的颜色
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #4C6FF0;
        border-color: #4C6FF0;
    }


    .router-link {
        text-decoration: none !important;
        margin-right: 48px;
        font-size: 14px;
        position: relative;
        color: #FFFFFF;

    }

    .router-link::before,
    .router-link::after {
        content: "";
        position: absolute;
        bottom: -20px;
        width: 0;
        height: 2px;
        background-color: #ffffff;
        transition: width 0.3s ease-in-out;
    }

    .router-link::before {
        left: 50%;
        transform: translateX(-50%);
    }

    .router-link::after {
        right: 50%;
        transform: translateX(50%);
    }

    .router-link.active-link::before,
    .router-link.active-link::after,
    .router-link:hover::before,
    .router-link:hover::after {
        width: 100%;
        transition: width 0.3s ease-in-out;
    }

    @keyframes backgroundShift {
        0% {
            background-position: right bottom;
        }

        100% {
            background-position: left bottom;
        }
    }


    @keyframes fadeIn {
        from {
            color: #9E99AE;
            opacity: 0;
        }

        to {
            color: #1A1E39;
            opacity: 1;
        }
    }

    // 旋转动画 
    @keyframes rotateY {
        from {
            // 开始时 为旋转
            transform: rotateY(180deg);
        }

        to {
            transform: rotateY(0deg);
        }
    }



    // 选中的tab样式、
    #activeTab {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        // color: #4C6FF0;
        color: #1A1E39;
        line-height: 28px;
        position: relative;
        animation: fadeIn 0.5s;

    }

    #activeTab::after {
        content: '';
        height: 4px;
        width: 25px;
        // background: #4C6FF0;
        background: #1A1E39;
        position: absolute;
        left: 26px;
        bottom: -10px;
        animation: fadeIn 0.5s;
    }

    // 注册
    .login-box-logo {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 32px;
    }

    .login-box-logo .box-logo-h1 {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #1A1E39;
        line-height: 28px;
        margin-top: 10px;
    }

    .login-box-logo .box-logo-p {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #4C6FF0;
        line-height: 22px;
    }




    // 登录注册
    .login {
        // width: 520px;
        border-radius: 8px;
        background: #FFFFFF;
        position: absolute;
        top: -10%;
        z-index: 999;
        // height: 522px;
        // 设置透视效果 ，增加3D效果的深度
        perspective: 1000px;
        // transform-style: preserve-3d;
        transform: rotateY(0deg);
        // animation-fill-mode: forwards;




        .login-cancel {
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
        }

        .login-cancel:hover {
            opacity: 0.5;
        }

        .login-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            /* width: 400px; */
          
            transition: all 0.5s ease;
            animation: fadeIn 1s;
         
            background: rgb(255, 255, 255);
         
            padding: 30px;
            border-radius: 8px;
          

            .login-box-tab {
                width: 261px;
                margin-bottom: 32px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .box-tab-t {
                    font-size: 20px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #9E99AE;

                    //color: #1A1E39;
                    line-height: 28px;
                    cursor: pointer;
                    transition: all 0.5 ease;
                }

                .box-tab-l {
                    width: 1px;
                    height: 20px;
                    background: #E9ECF3;
                }
            }


            .login-box-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;

                .box-content-inp2 {

                    height: 44px;

                    // background: #F6F7FB;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 16px;

                    .content-inp-dxCode {
                        width: 196px;
                        height: 44px;
                        border-radius: 4px;
                        background: #F6F7FB;
                        padding: 0 16px;
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        line-height: 44px;
                    }



                }

                .box-content-inp {
                    border-bottom: 1px solid rgb(221, 221, 221);
                    height: 44px;
                    // background: #F6F7FB;
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;

                    .content-inp-a {

                        height: 22px;
                        width: 40px;
                        border-right: 1px solid #E9ECF3;
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #655F76;
                        line-height: 22px;
                        margin-right: 12px;
                    }

                    .content-inp-phone {
                        width: 350px;
                        height: 22px;
                        // background: #F6F7FB;
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        line-height: 22px;
                    }


                    .content-inp-password {
                        width: 350px;
                        height: 22px;
                        // background: #F6F7FB;
                        font-size: 14px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        line-height: 22px;
                    }

                    .content-inp-eyes {

                        width: 14px;
                        height: 14px;
                        margin-left: 8px;
                        cursor: pointer;
                    }
                }
            }

            // 自动登录选项
            .box-content-select {
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;

                .content-select-box a {
                    text-decoration: none;
                    color: #4C6FF0;
                }

                .content-select-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;




                    .select-box-p {
                        margin-left: 4px;
                        font-size: 12px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #9E99AE;
                        line-height: 16px;
                        cursor: pointer;

                    }
                }
            }

            .box-content-btn {
                width: 100%;
                height: 44px;
                //  background: linear-gradient(90deg, hsl(256, 89%, 57%) 0%, #4C6FF0 25%, #FF4EAA 50%, #6430F3 75%, #4C6FF0 98%);
                // background: linear-gradient(90deg, #3650e4 0%, #A5D7D4 32%, #80CCCB 50%, #6687e0 75%, #33B0CB 98%);
                background: linear-gradient(90deg, #6430F3 0%, #05e6f7 32%, #1CF6EA 50%, #22d6ff 75%, #5985ff 100%);
                background-size: 200% 200%;
                border-radius: 22px 22px 22px 22px;
                text-align: center;
                line-height: 44px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                margin-bottom: 16px;
                transition: all 0.5s ease-in-out;
                background-position: left bottom;
                cursor: pointer;
            }

            .box-content-btn:hover {
                background-position: right bottom;

            }

            .box-content-jump {
                width: 100%;
                /* background: aquamarine; */
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;

                .content-jump-p {
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #9E99AE;
                    line-height: 22px;
                }

                .content-jump-a {
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #4C6FF0;
                    line-height: 16px;
                    cursor: pointer;
                }
            }

            .box-content-agree {
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: center;

                .content-agree-p {
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #9E99AE;
                    line-height: 22px;
                    margin-left: 4px;
                }

                .content-agree-a {
                    font-size: 12px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #4C6FF0;
                    line-height: 22px;
                }
            }

        }
    }


    .navbar {
        width: 100%;
        min-height: 72px;
        background: rgba(26, 30, 57, 0.6);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 999;
    }

    .nav-l {
        display: flex;
        align-items: center;
        margin-left: 32px;

        .logo {
            width: 47px;
            height: 32px;
        }

        .slgn {
            width: 68px;
            height: 20px;
            margin-left: 18px;
        }

    }

    .nav-m {
        //  background: salmon;
        height: 70px;
        display: flex;
        align-items: center;


    }

    .nav-r {
        display: flex;
        align-items: center;


        .r-login {
            width: 92px;
            height: 32px;
            border-radius: 40px 40px 40px 40px;
            border: 1px solid #FFFFFF;
            text-align: center;
            font-size: 14px;
            line-height: 32px;
            color: #FFFFFF;
            cursor: pointer;
        }

        .r-exit {
            width: 92px;
            height: 32px;
            border-radius: 40px 40px 40px 40px;
            border: 1px solid #FFFFFF;
            text-align: center;
            font-size: 14px;
            line-height: 32px;
            color: #FFFFFF;
            margin-left: 24px;
            margin-right: 32px;
            cursor: pointer;
        }

    }

    .m-item {
        display: flex;
        align-items: center;

        .m-item-img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }


    .content-inp-item1-img1 {
        width: 20px;
        height: 20px;
        background: url(../assets/image/icon-user.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 6px;
        // background-position: 0 0 ;
    }

    .content-inp-item1-img2 {
        width: 20px;
        height: 20px;
        background: url(../assets/image/icon-secure.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 6px;
        // background-position: 0 0 ;
    }

    .content-inp-item1-img3 {
        width: 20px;
        height: 20px;
        background: url(../assets/image/icon-lock.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 6px;
        // background-position: 0 0 ;
    }

    .content-inp2-btn {
        width: 100px;
        height: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid #4C6FF0;
        margin-left: 10px;


        .inp2-btn-p {
            font-size: 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #1A1E39;
            line-height: 44px;
            cursor: pointer;
        }
    }


    .content-inp-item1 {
        cursor: pointer;
        color: #6b6b6b;
        font-size: 15px;
    }

    .content-inp-item2 {
        cursor: pointer;
        color: #0dbdc4;
        font-size: 15px;
        position: relative;
    }


    /deep/ .vue-auth-box_ {
        position: absolute;
        top: 40%;
        left: 50.5%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 20px;
        background: #fff;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-radius: 3px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }

    .error-alert {
        height: 34px;
        line-height: 34px;
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 50%;
        top: 30%;
        z-index: 99;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;

        padding: 8px;
        transition: all 0.3s ease-in-out;
    }

    .error-alert img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }

    .error-alert span {
        font-size: 12px;
        color: #fff;

        line-height: 20px;
    }

    //过度开始状态
    .fade2-enter-active,
    .fade2-leave-active {
        transition: opacity 0.5s;
    }

    // 过度结束状态
    .fade2-enter,
    .fade2-leave-to

    /* .fade-leave-active 在Vue 2.1.8+ */
        {
        opacity: 0;
    }
</style>