// 引入Vue
import Vue from 'vue'
// 引入Vuex
import Vuex from 'vuex'
// 添加全局引用
Vue.use(Vuex);

// 创建state
const state = {
  // 定义item属性用于存储传递的item对象
  categoryItem: null,
  ids: undefined,
  isBatch: true
}

// Getter，相当于store里面的计算属性
const getters = {
  // 获取存储的item对象
  getCategoryItem: state => state.categoryItem
}

// 改变状态，通过commit
const mutations = {
  // 存储item对象
  setCategoryItem(state, item) {
    state.categoryItem = item;
  },
  ChangeStateWithPara(state, para) {
    state.ids = para;
  },
  ChangeStateWithPara2(state, para) {
    state.isBatch = para;
  }
}

// 用来管理mutations
const actions = {
  // 执行存储item的操作
  storeCategoryItem({ commit }, item) {
    commit('setCategoryItem', item);
  }
}

// 创建store对象
const store = new Vuex.Store({
  state,
  mutations,
  getters,
  actions
})

// 导出创建的store对象
export default store;