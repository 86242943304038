//引入第一层axios
import http from '@/utils/http.js';
//普通暴露（data为调用函数时需要传入的参数对象）


//文章类型 nav
export const articleTypeList = (data) => {
    return http({
        method: 'get',
        url: '/article/articleTypeList',
        data,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}

//推荐文章
export const suggestArticle = (params) => {
    return http({
        method: 'get',
        url: '/article/suggestArticle',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}

// 文章列表
export const articleList = (params) => {
    return http({
        method: 'get',
        url: '/article/articleList',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}

// 文章可能感兴趣
export const concernArticle = (params) => {
    return http({
        method: 'get',
        url: '/article/concernArticle',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}

// 文章详情
export const articleDetail = (params) => {
    return http({
        method: 'get',
        url: '/article/articleDetail',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}


// 按文章类型搜索
export const articleTypeSearch = (params) => {
    return http({
        method: 'get',
        url: '/article/articleTypeSearch',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}

// 搜索
export const articleSearch = (params) => {
    return http({
        method: 'get',
        url: '/article/articleSearch',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}


// 关于网站列表
export const aboutWebsiteList = (params) => {
    return http({
        method: 'get',
        url: '/article/aboutWebsiteList',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}

// 关于网站详情
export const aboutWebsiteDetail = (params) => {
    return http({
        method: 'get',
        url: '/article/aboutWebsiteDetail',
        params,
        // 设置请求头，告知服务器将要发送的是表单数据
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
    })
}
